import * as React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Testimonials from '../components/Testimonials'
import styled from 'styled-components'

export const netballTestimonials = [
  {
    content: `I reckon that no matter what the vocation or sport, you can't beat
    knowledge, skill and experience. Jean Luc at Rainbow Paragliding has
    these attributes in spades. I found his high regard for safety comforting and his deep knowledge of
    weather conditions, reassuring. Paragliding can be a dangerous sport
    but Jean Luc is good at teaching people how to know their limits and
    to practice skills that take much of the danger away.`,
    name: 'David Tollner',
    company: 'Rainbow Beach',
  },
  {
    content: `I had the pleasure of flying with David (Senior Safety Officer,
        Tandem Hang Gliding Pilot and Microlight Pilot) in his microlight
        around the shore lines of Noosa. I would have to say that it has been
        one of the major highlights of my life. The view was spectacular, the
        freedom you feel when you're up in the air is mind blowing. David is
        very experienced, funny and friendly, I felt absolutely safe and
        comfortable with him. A must for everyone to experience. Loved it.`,
    name: 'Kirstin',
    company: 'Sunshine Coast',
  },
  {
    content: `Lisa and her team recognize and appreciate that every student has a
      different background, wants and needs. Training is only progressed at
      an individual’s pace, in incremental steps so they quickly gain
      mastery and feel they’ve achieved something significant from the very
      early stages of their course.`,
    name: 'OZParagliding Student',
    company: 'Canungra/Gold Coast',
  },
  {
    content: `Paratech's Chief Flight Instructor, Jason Turner, has over 29 years
      flying experience with paragliders, hang gliders, sailplanes and
      ultralight aircraft. A full time Instructor for over 15 years, Jason
      is well known for his calm, comprehensive and adaptive teaching style
      with a high regard for student safety. Jason is still a formidable and
      well respected pilot with recent podium finishes in competitions,
      several long distance record flights and has much more to offer our
      students than most entry level instructors, thanks to his extensive
      and proven flying background.`,
    name: 'Jason Turner',
    company: 'Paratech PG',
  },
]

const StyledIframe = styled.iframe`
  height: 1100px;
  border: 0;
  @media (min-width: 1700px) {
    height: 1100px;
  }

  @media (max-width: 1300px) {
    height: 1100px;
  }
  @media (max-width: 1200px) {
    height: 1200px;
  }
  @media (max-width: 800px) {
    height: 1300px;
  }
  @media (max-width: 570px) {
    height: 1600px;
  }
  @media (max-width: 480px) {
    height: 1900px;
  }
`

const StyledReviews = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 800px;
  overflow: hidden;
  .gradeus-button-container {
    iframe {
      margin-left: 15px;
      @media (max-width: 850px) {
        margin-left: 25px;
        margin-bottom: 20px;
      }
    }
  }
`

export const allTestimonials = [...netballTestimonials]

class Reviews extends React.Component {
  // componentDidMount() {
  //   const method = window.addEventListener ? 'addEventListener' : 'attachEvent'
  //   const event = window.addEventListener ? 'message' : 'onmessage'
  //   window[method](
  //     event,
  //     e => {
  //       if ('review_iframe_height' in e.data) {
  //         document.getElementById('myFrame').height =
  //           e.data.review_iframe_height + 'px'
  //       }
  //     },
  //     false
  //   )
  // }

  componentDidMount() {}
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Flight Experience Testimonials - Queensland Sports Aviators
          </title>
          <meta
            name="description"
            content="Curious about what others think? Here are some testimonials from a few of our fellow aviators."
          />
        </Helmet>
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          style={{ margin: '10rem 0 100px' }}
        >
          <Testimonials
            testimonials={allTestimonials}
            style={{
              borderRadius: 8,
              boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
            }}
          />
        </Flex>
      </Layout>
    )
  }
}

export default Reviews
